import React, { useState } from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";
import "../global.scss";
import logo from "../images/ryotei-logo.png";
import loader from "../images/loader.gif";
import addToMailchimp from "gatsby-plugin-mailchimp";

const IndexPage = () => {
  const [email, setEmail] = useState(null);
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    errorMsg: null,
    successMsg: null,
  });

  const { isLoading, errorMsg, successMsg } = loadingState;

  const handleSubmit = async event => {
    event.preventDefault();
    console.log("--handleSubmit--", email);

    setLoadingState({
      isLoading: true,
      errorMsg: null,
      successMsg: null,
    });

    try {
      const response = await addToMailchimp(email);
      console.log("--success--");
      console.log(response);
      if (response.result === "error") {
        const alreadySubscribed =
          response.msg.indexOf("is already subscribed") !== -1;
        setLoadingState({
          isLoading: false,
          successMsg: null,
          errorMsg: alreadySubscribed
            ? "You're already subscribed!"
            : response.msg,
        });
      } else {
        setLoadingState({
          isLoading: false,
          errorMsg: null,
          successMsg: "Thank you for signing up!",
        });
      }
    } catch (e) {
      console.log("--there was an error--");
      console.log(e);
      setLodingState({
        isLoading: false,
        errorMsg: e.errorMsg,
        successMsg: null,
      });
    }
  };

  const handleChangeText = event => {
    const email = event.target.value;
    setEmail(email);
  };

  return (
    <div className="container">
      <SEO title="Home" />
      <img className="logo" src={logo} />
      <div className="hero">
        <div className="hero-inner">
          <h1>The perfect trip with your crew</h1>
          <h2>Dream and organize awesome vacations with your friends.</h2>

          {successMsg == null && (
            <form onSubmit={handleSubmit}>
              <input
                className="emailField"
                onChange={handleChangeText}
                placeholder="Enter your email"
              />
              <button type="submit" className="signupBt" disabled={isLoading}>
                {isLoading ? <img src={loader} alt="loader" /> : "sign up"}
              </button>
            </form>
          )}

          {errorMsg !== null && (
            <div className="formResult error">{errorMsg}</div>
          )}
          {successMsg !== null && (
            <div className="formResult success">{successMsg}</div>
          )}
        </div>
      </div>
      <footer>
        © {new Date().getFullYear()}{" "}
        <a href="https://twitter.com/groundcontrol">@groundcontrol</a>
      </footer>
    </div>
  );
};

export default IndexPage;
